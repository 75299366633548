import './App.css'
import SiteRouter from './routes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cmsAuthActions } from './store';
import { checkToken } from './store/authSlice';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => { dispatch(checkToken()) }, [])
  const dispatch = useDispatch()
  return (<>
    <div className="App">
      <SiteRouter />
    </div>
  </>
  );
}
export default App;