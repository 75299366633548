
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { List, Tag } from "antd"
import { getAllMedia } from "../../store/mediaSlice";
import { cmsMediaSelectActions } from "../../store";
import ImageCard from "./ImageCard";
import classes from "./MediaSelectDialog.module.css"
import GreenButton from "../buttons/GreenButton";
import RedButton from "../buttons/RedButton";
import VideoCard from "./VideoCard";
import UploadButton from "../buttons/UploadButton";
import { useNavigate } from "react-router-dom";

const MediaSelectDialog = (props) => {
    useEffect(() => {
        if (!media.length) dispatch(getAllMedia());
    }, [])
    const media = useSelector(state => state.cmsMedia.list);
    const selectedItem = useSelector(state => state.cmsMediaSelect.list);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { multiple, ...params } = props;

    return (
        <div {...params}
            className={classes["modal-select-dialogbox"]}
        >
            <div className={classes["header"]}>
                <h4>Select Media</h4>
                <h6>Selected Media: {selectedItem?.map((item, index) =>
                    <Tag
                        color={item.caption !== "undefined" ? "#0034f0" : "#f01400"}
                        key={index}
                    // closable
                    // onClose={() => {
                    //     setSelectedItem(prevState => {
                    //         const itemIndex = prevState.findIndex(x => x.src === item.src);
                    //         prevState.splice(itemIndex, 1);
                    //         let temp = prevState;
                    //         temp.splice(itemIndex, 1);
                    //         return [...temp];
                    //     })
                    // }}
                    >
                        {item.caption}
                    </Tag>)}
                </h6>
                <UploadButton onClick={() => {
                    props.onCancel();
                    navigate("/media")
                }}>Add Media</UploadButton>
            </div>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    pageSize: 28,
                    size: "small",
                    showSizeChanger: false,
                }}
                className={classes["list"]}
                dataSource={media}
                grid={{ gutter: 2, column: 7, row: 3 }}
                renderItem={(item) => (
                    <List.Item
                        key={item._id}
                        bordered={true}
                    >
                        {item?.type === "image" && <ImageCard
                            imgProps={{
                                preview: false,
                                width: 110,
                                height: 80,
                                src: item?.url,
                                alt: item?.alt,
                                caption: item?.title,
                                onLoad: () => {
                                    return <h4>Loading...</h4>
                                }
                            }}
                            multiple={multiple}
                        />}
                        {item?.type === "video" && <VideoCard
                            videoProps={{
                                width: 110,
                                height: 80,
                                autoPlay: true,
                                muted: true,
                                loop: true,
                                caption: item?.title,
                                src: item?.url,
                            }}
                            multiple={multiple}

                        />}
                        {item?.type === "pdf" && <h4>This is a PDF!</h4>}

                    </List.Item>
                )}
            />
            <div className={classes["footer"]}>
                <GreenButton onClick={props.onConfirm}>Add</GreenButton>
                <RedButton onClick={() => {
                    dispatch(cmsMediaSelectActions.initializeList());
                    props.onCancel();
                }}>
                    Cancel
                </RedButton>
            </div>

        </div>
    )
}
export default MediaSelectDialog;