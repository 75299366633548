import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "../../../config/axios";
import classes from "./elements.module.css";
export default function MyEditor({ onChange, value, ...props }) {
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            axios
              .post("/admin/media", body)
              .then((res) => {
                resolve({
                  default: res.data.media.url,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className={classes.editor}>
      <section className={classes.label}>{props.label}</section>
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin],
        }}
        editor={Editor}
        onReady={(editor) => {}}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange({
            target: {
              value: data,
            },
          });
        }}
        data={value}
      />
      <h5 style={{ color: "black" }}>{props.info && props.info}</h5>
    </div>
  );
}
