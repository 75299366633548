import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";

export const getAllAdvertisement = createAsyncThunk(
    "advertisement/getAllAdvertisement",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/advertisement");
            return response.data.advertisement;
        }
        catch (err) {
            return rejectWithValue("Failed to get all advertisement!");
        }
    })

export const searchAdvertisement = createAsyncThunk(
    "advertisement/searchAdvertisement",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/advertisement/search/:${params.searchKey}`);
            return (await response).data.advertisement;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)

export const postAdvertisement = createAsyncThunk(
    "advertisement/postAdvertisement",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post("/admin/advertisement", params.formData);
            params.resetForm();
            return await response.data.advertisement;
        } catch (err) {
            return rejectWithValue("Failed to add advertisement!");
        }
    }
)
export const putAdvertisement = createAsyncThunk(
    "advertisement/putAdvertisement",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.put("/admin/advertisement", params.formData);
            const payLoad = (await response).data.advertisement;
            params.navigate();
            return (await response).data.advertisement;
        } catch (err) {
            return rejectWithValue("Update failed!");
        }
    }
)

export const changeIsPublishStatus = createAsyncThunk(
    "advertisement/changeIsPublishStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/advertisement/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.advertisement;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const getOneAdvertisement = createAsyncThunk(
    "advertisement/getOneAdvertisement",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/advertisement/get/${params.advertisementId}`)
            params.fillForm(response.data.advertisement);
            return response.data.advertisement;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const deleteAdvertisement = createAsyncThunk(
    "advertisement/deleteAdvertisement",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/advertisement/delete/${params._id}`);
            return (await response).data.advertisement;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

const advertisementSlice = createSlice({
    name: "advertisement",
    initialState: {
        list: [],
        editModeAdvertisement: null,
    },
    extraReducers: {
        [getAllAdvertisement.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [getAllAdvertisement.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch advertisements!" })
        },
        [postAdvertisement.pending]: (state) => {
            notification("info", { message: "Working on it!" });
        },
        [postAdvertisement.fulfilled]: (state, action) => {
            state.list.push(action.payload)
            notification("success", { message: "Success.", description: "Advertisement saved successfully." });
        },
        [postAdvertisement.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [putAdvertisement.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putAdvertisement.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putAdvertisement.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Advertisement doesn't exist or validation error! " });
        },
        [changeIsPublishStatus.pending]: (state, action) => {
            // notification("info", { message: "Working on it!." });
        },
        [changeIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Advertisement doesn't exist or server error! " });
        },
        [deleteAdvertisement.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteAdvertisement.fulfilled]: (state, action) => {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deleteAdvertisement.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Advertisement doesn't exist or server error! " });
        },
        [searchAdvertisement.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchAdvertisement.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
        [getOneAdvertisement.pending]: (state) => {
            notification("info", { message: "Fetching advertisement info!" });
        },
        [getOneAdvertisement.fulfilled]: (state) => {
            notification("success", { message: "Successfully fetched." });
        },
        [getOneAdvertisement.rejected]: (state) => {
            notification("error", { message: "Server error!" });
        }
    }
})
export default advertisementSlice;