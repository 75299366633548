import { Image } from "antd"
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import classes from "./ImageCard.module.css";
import { TiTickOutline } from "react-icons/ti";
import isExistInArray from "../../utils/isExistsInArray";
import { useEffect } from "react";
import { cmsMediaSelectActions } from "../../store";
const VideoCard = (props) => {
    const selectedList = useSelector(state => state.cmsMediaSelect.list)
    useEffect(() => {
        const { status, index } = isExistInArray(selectedList, { src, caption });
        setIsSelected(status)
    }, [selectedList]);
    const { videoProps, ...params } = props;
    const { src, caption } = videoProps;
    const [isSelected, setIsSelected] = useState(false);
    const dispatch = useDispatch();
    return (
        <div
            className={`${classes["image-card"]} ${isSelected && classes["selected"]}`}
            onClick={(e) => {
                e.stopPropagation();
                !isSelected && !params.multiple && dispatch(cmsMediaSelectActions.addSelectedItem({ media: { src, caption, "type": "video" }, mode: "single" }));
                isSelected && !params.multiple && dispatch(cmsMediaSelectActions.deleteSelectedItem({ media: { src, caption, "type": "video" }, mode: "single" }));
                !isSelected && params.multiple && dispatch(cmsMediaSelectActions.addSelectedItem({ media: { src, caption, "type": "video" }, mode: "multiple" }));
                isSelected && params.multiple && dispatch(cmsMediaSelectActions.deleteSelectedItem({ media: { src, caption, "type": "video" }, mode: "multiple" }));
            }}
        >

            <video {...videoProps} stype={{ "object-fit": "fill" }}>
                <source src={src} type="video/mp4" />
            </video>
            <p className={classes["caption"]}>{caption}</p>
            {isSelected ? <p className={classes["tick-icon"]}><TiTickOutline color="white" /></p> : (() => { })()}
        </div >
    )
}
export default VideoCard