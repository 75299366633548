import React, { useEffect } from "react";
import { useDispatch } from "react-redux/";
import DarkBlueButton from "../../../components/buttons/DarkBlueButton";
import { cmsPwpActions, cmsAuthActions } from "../../../store";
import { BiLogOut } from "react-icons/bi"
import classes from "./user.module.css"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Form from "../../../components/form/Form";
import FormControls from "../../../components/form/FormControls";
import FormControl from "../../../components/form/FormControl";
import FormActions from "../../../components/form/FormActions";
import FormAction from "../../../components/form/FormAction";
import useInput from "../../../hooks/useInput";
import { resetPassword } from "../../../store/authSlice";


function User ()  {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(cmsPwpActions.changePwp(["Dashboard", "User"]));
    }, []);
    const [reset, setReset] = useState(false)
    const navigate = useNavigate()

    const {
        value: enteredOldPassword,
        setValue: setOldPassword,
        hasError: enteredOldPasswordHasError,
        reset: resetOldPassword,
        valueChangeHandler: oldPasswordChangeHandler,
        inputBlurHandler: oldPasswordBlurHandler
    } = useInput(value => value?.length);

    const {
        value: enteredNewPassword,
        setValue: setNewPassword,
        hasError: enteredNewPasswordHasError,
        reset: resetNewPassword,
        valueChangeHandler: newPasswordChangeHandler,
        inputBlurHandler: newPasswordBlurHandler
    } = useInput(value => value?.length);

    const {
        value: enteredConfirmPassword,
        setValue: setConfirmPassword,
        hasError: enteredConfirmPasswordHasError,
        // eslint-disable-next-line no-unused-vars
        reset: resetConfirmPassword,
        valueChangeHandler: confirmPasswordChangeHandler,
        inputBlurHandler: confirmPasswordBlurHandler
    } = useInput(value => value === enteredNewPassword);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!enteredOldPasswordHasError && !enteredNewPasswordHasError && !enteredConfirmPasswordHasError) {
            const formData = new FormData()
            formData.append("oldPassword", enteredOldPassword);
            formData.append("newPassword", enteredNewPassword);
            dispatch(resetPassword({ formData, navigate: () => navigate("/login") }))
        }
    }

    return (<>
        <div className={classes["user-panel"]}>
            <DarkBlueButton onClick={() => {
                dispatch(cmsAuthActions.logout())
                navigate("/login")
            }}><BiLogOut style={{ display: "inline-block", textAlign: "center", marginRight: "10px" }} /> Logout </DarkBlueButton>
            <DarkBlueButton onClick={() => setReset(prevState => !prevState)}>Reset Password</DarkBlueButton>
            {reset ?
                <div className={classes["pass-reset-form"]}>
                    <Form
                        title={"Reset your Password."}
                        onSubmit={handleSubmit}
                    >
                        <FormControls>
                            <FormControl
                                type="password"
                                label="Old Password"
                                name="oldPassword"
                                placeholder="Old password here."
                                errormessage="Password is required"
                                required
                                value={enteredOldPassword}
                                error={enteredOldPasswordHasError}
                                onChange={oldPasswordChangeHandler}
                                onBlur={oldPasswordBlurHandler}
                            />
                            <FormControl
                                type="password"
                                label="New Password"
                                name="newPassword"
                                placeholder="New password here."
                                errormessage="Password is required"
                                required
                                value={enteredNewPassword}
                                error={enteredNewPasswordHasError}
                                onChange={newPasswordChangeHandler}
                                onBlur={newPasswordBlurHandler}
                            />
                            <FormControl
                                type="password"
                                label="Confirm Password"
                                name="confirmPassword"
                                placeholder="Confirm password here."
                                errormessage="Password do not match."
                                required
                                value={enteredConfirmPassword}
                                error={enteredConfirmPasswordHasError}
                                onChange={confirmPasswordChangeHandler}
                                onBlur={confirmPasswordBlurHandler}
                            />
                        </FormControls>
                        <FormActions>
                            <FormAction type="submit">Submit</FormAction>
                        </FormActions>
                    </Form>
                </div>
                : null}

        </div>
    </>)
}

export default User