import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";
import { cmsNoticeActions } from "./index"
import parseFormData from "../utils/parseFormData"

export const getInlineNotice = createAsyncThunk(
    "notice/getInlineNotice",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/notice/inline");
            return response.data.inlineNotice;
        }
        catch (err) {
            return rejectWithValue("Failed to get all notice!");
        }
    }
)

export const postInlineNotice = createAsyncThunk(
    "notice/postInlineNotice",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post("/admin/notice/inline", params.formData);
            params.resetForm();
            return await response.data.inlineNotice;
        } catch (err) {
            return rejectWithValue("Failed to add notice!");
        }
    }

)

export const putInlineNotice = createAsyncThunk(
    "notice/putInlineNotice",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put("/admin/notice/inline", params.formData);
            const formData = parseFormData(params.formData);
            dispatch(cmsNoticeActions.updateInlineNotice(formData));
            params.resetForm();
            return response.data.inlineNotice;
        } catch (err) {
            return rejectWithValue("Update failed!");
        }
    }
)

export const changeInlineIsPublishStatus = createAsyncThunk(
    "notice/changeInlineIsPublishStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/notice/inline/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.inlineNotice;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const getOneInlineNotice = createAsyncThunk(
    "notice/getOneInlineNotice",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/notice/get/inline/${params.noticeId}`)
            params.fillForm(response.data.notice);
            return response.data.notice;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const deleteInlineNotice = createAsyncThunk(
    "notice/deleteInlineNotice",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.delete(`/admin/notice/inline/delete/${params._id}`);
            return (await response).data.inlineNotice;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)



export const getPortalNotice = createAsyncThunk(
    "notice/getPortalNotice",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/notice/portal");
            return response.data.portalNotice;
        }
        catch (err) {
            return rejectWithValue("Failed to get all notice!");
        }
    }
)



export const postPortalNotice = createAsyncThunk(
    "notice/postPortalNotice",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post("/admin/notice/portal", params.formData);
            params.resetForm();
            return await response.data.portalNotice;
        } catch (err) {
            return rejectWithValue("Failed to add notice!");
        }
    }
)

export const putPortalNotice = createAsyncThunk(
    "notice/putPortalNotice",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put("/admin/notice/portal", params.formData);
            const formData = parseFormData(params.formData);
            dispatch(cmsNoticeActions.updatePortalNotice(formData));
            params.resetForm();
            return response.data.portalNotice;
        } catch (err) {
            return rejectWithValue("Update failed!");
        }
    }
)

export const changePortalIsPublishStatus = createAsyncThunk(
    "notice/changePortalIsPublishStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/notice/portal/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.portalNotice;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const getOnePortalNotice = createAsyncThunk(
    "notice/getOnePortalNotice",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/notice/get/portal/${params.noticeId}`)
            params.fillForm(response.data.portalNotice);
            return response.data.portalNotice;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const deletePortalNotice = createAsyncThunk(
    "notice/deletePortalNotice",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.delete(`/admin/notice/portal/delete/${params._id}`);
            return (await response).data.portalNotice;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)



const noticeSlice = createSlice({
    name: "notice",
    initialState: {
        inlineNotifications: [],
        selectedInlineNotification: null,
        portalNotifications: [],
        selectedPortalNotification: null
    },
    reducers: {
        editInlineNotification(state, action) {
            state.selectedInlineNotification = action.payload;
        },
        editPortalNotification(state, action) {
            state.selectedPortalNotification = action.payload;
        },
        initializeSelectedInlineNotice(state, action) {
            state.selectedInlineNotification = null;
        },
        initializeSelectedPortalNotice(state, action) {
            state.selectedPortalNotification = null;
        },
        updateInlineNotice(state, action) {
            const objectIndex = state.inlineNotifications.findIndex(item => item._id === action.payload._id);
            objectIndex < 0 ? (() => { })() : (() => {
                state.inlineNotifications[objectIndex].title = action.payload.title;
                state.inlineNotifications[objectIndex].isPublish = action.payload.isPublish;
                state.inlineNotifications[objectIndex].description = action.payload.description;
            })()
            state.selectedInlineNotification = null;
        },
        updatePortalNotice(state, action) {
            const objectIndex = state.portalNotifications.findIndex(item => item._id === action.payload._id);
            objectIndex < 0 ? (() => { })() : (() => {
                state.portalNotifications[objectIndex].title = action.payload.title;
                state.portalNotifications[objectIndex].isPublish = action.payload.isPublish;
                state.portalNotifications[objectIndex].image = action.payload.image;
                state.portalNotifications[objectIndex].description = action.payload.description;
            })()
            state.selectedInlineNotification = null;
        },
    },
    extraReducers: {
        [getInlineNotice.fulfilled]: (state, { payload }) => {
            state.inlineNotifications = payload;
        },
        [getInlineNotice.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch notices!" })
        },
        [getInlineNotice.pending]: (state) => {
            notification("info", { message: "Working on it!" });
        },
        [postInlineNotice.fulfilled]: (state, action) => {
            state.inlineNotifications = [action.payload, ...state.inlineNotifications]
            notification("success", { message: "Success.", description: "Inline Notice saved successfully." });
        },
        [postInlineNotice.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [postInlineNotice.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putInlineNotice.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putInlineNotice.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Notice doesn't exist or validation error! " });
        },
        [changeInlineIsPublishStatus.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [changeInlineIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeInlineIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Notice doesn't exist or server error! " });
        },
        [deleteInlineNotice.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteInlineNotice.fulfilled]: (state, action) => {
            !state.inlineNotifications.length ? (() => { })() : (() => {
                const objectIndex = current(state).inlineNotifications.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.inlineNotifications.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deleteInlineNotice.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Notice doesn't exist or server error! " });
        },

        [getOneInlineNotice.pending]: (state) => {
            notification("info", { message: "Fetching notice info!" });
        },
        [getOneInlineNotice.fulfilled]: (state) => {
            notification("success", { message: "Successfully fetched." });
        },
        [getOneInlineNotice.rejected]: (state) => {
            notification("error", { message: "Server error!" });
        },

        [getPortalNotice.fulfilled]: (state, { payload }) => {
            state.portalNotifications = payload;
        },
        [getPortalNotice.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch notices!" })
        },
        [getPortalNotice.pending]: (state) => {
            notification("info", { message: "Working on it!" });
        },
        [postPortalNotice.fulfilled]: (state, action) => {
            state.portalNotifications = [action.payload, ...state.portalNotifications]
            notification("success", { message: "Success.", description: "Portal Notice saved successfully." });
        },
        [postPortalNotice.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [postPortalNotice.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putPortalNotice.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putPortalNotice.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Notice doesn't exist or validation error! " });
        },
        [changePortalIsPublishStatus.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [changePortalIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changePortalIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Notice doesn't exist or server error! " });
        },
        [deletePortalNotice.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deletePortalNotice.fulfilled]: (state, action) => {
            !state.portalNotifications.length ? (() => { })() : (() => {
                const objectIndex = current(state).portalNotifications.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.portalNotifications.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deletePortalNotice.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Notice doesn't exist or server error!" });
        },
        [getOnePortalNotice.pending]: (state) => {
            notification("info", { message: "Fetching notice info!" });
        },
        [getOnePortalNotice.fulfilled]: (state) => {
            notification("success", { message: "Successfully fetched." });
        },
        [getOnePortalNotice.rejected]: (state) => {
            notification("error", { message: "Server error!" });
        },
    }
})
export default noticeSlice;