import classes from "./PreviewCard.module.css";
import { Image } from "antd"
const PreviewCard = (props) => {
    const { item } = props;
    return (
        <div className={classes["card"]}>
            {item.type === "image" && <Image
                key={item?._id}
                src={item?.src}
                style={{
                    border: "1.5px solid rgb(177, 177, 177)",
                    margin: "0rem .5rem 0rem .5rem"
                }}
                height={150}
                width={200}
            />}

            {item.type === "video" && <video
                height={150}
                width={200}
                autoPlay
                muted
                loop
                controls
                poster="https://www.citypng.com/public/uploads/preview/loading-load-icon-transparent-png-11639609114lctjenyas8.png"
            >
                <source src={item?.src} type="video/mp4" />
            </video>}

        </div>
    )
}
export default PreviewCard