import { configureStore } from "@reduxjs/toolkit"
import cmsPwpSlice from "./pwpSlice"
import cmsUserSlice from "./userSlice"
import cmsMediaSlice from "./mediaSlice"
import cmsAdvertisementSlice from "./advertisementSlice"
import cmsDrawerSlice from "./drawerSlice"
import cmsTeamSlice from "./teamSlice"
import cmsTestimonialSlice from "./testimonialSlice"
import cmsFaqSlice from "./faqSlice"
import cmsFaqCategorySlice from "./faqCategorySlice"
import cmsMediaSelectSlice from "./mediaSelectSlice"
import cmsBannerSlice from "./bannerSlice"
import cmsProjectSlice from "./projectSlice"
import cmsContactSlice from "./contactSlice"
import cmsBlogSlice from "./blogSlice"
import cmsSiteSettingSlice from "./siteSettingSlice"
import cmsSubscriberSlice from "./subscriberSlice"
import cmsServiceSlice from "./serviceSlice"
import cmsAboutSlice from "./aboutSlice"
import cmsAuthSlice from "./authSlice"
import cmsNoticeSlice from "./noticeSlice"


const store = configureStore({
    reducer: {
        cmsUser: cmsUserSlice.reducer,
        cmsPwp: cmsPwpSlice.reducer,
        cmsMedia: cmsMediaSlice.reducer,
        cmsAdvertisement: cmsAdvertisementSlice.reducer,
        cmsBanner: cmsBannerSlice.reducer,
        cmsDrawer: cmsDrawerSlice.reducer,
        cmsTeam: cmsTeamSlice.reducer,
        cmsTestimonial: cmsTestimonialSlice.reducer,
        cmsFaq: cmsFaqSlice.reducer,
        cmsFaqCategory: cmsFaqCategorySlice.reducer,
        cmsMediaSelect: cmsMediaSelectSlice.reducer,
        cmsProject: cmsProjectSlice.reducer,
        cmsContact: cmsContactSlice.reducer,
        cmsBlog: cmsBlogSlice.reducer,
        cmsSiteSetting: cmsSiteSettingSlice.reducer,
        cmsSubscriber: cmsSubscriberSlice.reducer,
        cmsService: cmsServiceSlice.reducer,
        cmsAbout: cmsAboutSlice.reducer,
        cmsAuth: cmsAuthSlice.reducer,
        cmsNotice: cmsNoticeSlice.reducer,

    }
});

export default store;
export const cmsUserActions = cmsUserSlice.actions
export const cmsPwpActions = cmsPwpSlice.actions
export const cmsAdvertisementActions = cmsAdvertisementSlice.actions
export const cmsMediaActions = cmsMediaSlice.actions
export const cmsDrawerActions = cmsDrawerSlice.actions
export const cmsTeamActions = cmsTeamSlice.actions
export const cmsTestimonialActions = cmsTestimonialSlice.actions
export const cmsFaqActions = cmsFaqSlice.actions
export const cmsFaqCategoryActions = cmsFaqCategorySlice.actions
export const cmsMediaSelectActions = cmsMediaSelectSlice.actions
export const cmsProjectActions = cmsProjectSlice.actions
export const cmsContactActions = cmsContactSlice.actions
export const cmsBlogActions = cmsBlogSlice.actions
export const cmsSiteSettingActions = cmsSiteSettingSlice.actions
export const cmsSubscriberActions = cmsSubscriberSlice.actions
export const cmsServiceActions = cmsServiceSlice.actions
export const cmsAboutActions = cmsAboutSlice.actions
export const cmsAuthActions = cmsAuthSlice.actions
export const cmsNoticeActions = cmsNoticeSlice.actions
