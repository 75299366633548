import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";

export const getSiteSetting = createAsyncThunk(
    "siteSetting/getSiteSetting",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/siteSetting");
            params.fillForm(response.data.siteSetting);
            return response.data.siteSetting;
        }
        catch (err) {
            return rejectWithValue("Failed to fetch setting!");
        }
    })

export const postSiteSetting = createAsyncThunk(
    "siteSetting/postSiteSetting",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post("/admin/siteSetting", params.formData);
            // params.resetForm();
            return await response.data.siteSetting;
        } catch (err) {
            return rejectWithValue("Failed to add Site settings!");
        }
    }
)

const websiteSettingSlice = createSlice({
    name: "siteSetting",
    initialState: {
        settings: null,
    },
    extraReducers: {
        [getSiteSetting.fulfilled]: (state, { payload }) => {
            state.settings = payload;
        },
        [getSiteSetting.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch site settings!" })
        },
        [postSiteSetting.pending]: (state) => {
            notification("info", { message: "Working on it!" });
        },
        [postSiteSetting.fulfilled]: (state, action) => {
            state.settings = action.payload;
            notification("success", { message: "Success.", description: "Site settings saved successfully." });
        },
        [postSiteSetting.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
    }
})
export default websiteSettingSlice;
