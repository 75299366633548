import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";
import { cmsTestimonialActions } from "./index";
export const postTestimonial = createAsyncThunk(
    "testimonial/postTestimonial",
    async (params, { rejeceWithValue, dispatch }) => {
        try {
            const response = await axios.post("/admin/testimonial", params.formData);
            params.resetForm();
            params.navigate();
            return response.data.testimonial;
        }
        catch (err) {
            return rejeceWithValue("Error posting testimonial!");
        }
    }
)

export const deleteTestimonial = createAsyncThunk(
    "testimonial/deleteTestimonial",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.get(`/admin/testimonial/delete/${params._id}`);
            dispatch(cmsTestimonialActions.deleteTestimonial(response.data.testimonial));
            return response.data.testimonial;
        }
        catch (err) {
            return rejectWithValue("Failed to delete testimonial!");
        }
    }
)

export const getAllTestimonial = createAsyncThunk(
    "testimonial/getAllTestimonial",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.get("/admin/testimonial");
            return response.data.testimonial;
        }
        catch (err) {
            return rejectWithValue("Falied to load testimonial.")
        }
    }
)

export const getOneTestimonial = createAsyncThunk(
    "testimonial/getOneTestimonial",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/testimonial/get/${params._id}`);
            params.fillForm(response.data.testimonial);
            return response.data.testimonial;
        }
        catch (err) {
            return rejectWithValue("Failed To Fetch Testimonial Info");
        }
    }
)

export const changeIsPublishStatus = createAsyncThunk(
    "testimonial/changeIsPublishStatus",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.get(`/admin/testimonial/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.testimonial;
        }
        catch (err) {
            return rejectWithValue("Failed to change status");
        }
    }
)

export const putTestimonial = createAsyncThunk(
    "testimonial/putTestimonial",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put("/admin/testimonial", params.formData);
            params.navigate();
            return response.data.testimonial;
        }
        catch (err) {
            return rejectWithValue("Failed to update data!");
        }
    }
)

export const searchTestimonial = createAsyncThunk(
    "testimonial/searchTestimonial",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/testimonial/search/:${params.searchKey}`);
            return response.data.testimonial;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)

const testimonialSlice = createSlice({
    name: "testimonial",
    initialState: { list: [] },
    reducers: {
        addOneTestimonialToList(state, action) {
            state.list = [action.payload, ...state.list];
        },
        deleteTestimonial(state, action) {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
        },
    },
    extraReducers: {
        [postTestimonial.pending]: (state, action) => {
            notification("info", { message: "Working on It!" });
        },
        [postTestimonial.fulfilled]: (state, action) => {
            notification("success", { message: "Saved Successfully." });
        },
        [postTestimonial.rejected]: (state, action) => {
            notification("error", { message: "Error Saving Data!" });
        },
        [deleteTestimonial.pending]: (state, action) => {
            notification("info", { message: "Working on It!" });
        },
        [deleteTestimonial.fulfilled]: (state, action) => {
            notification("success", { message: "Deleted Successfully." });
        },
        [deleteTestimonial.rejected]: (state, action) => {
            notification("error", { message: "Error Deleteing Data!" });
        },
        [getAllTestimonial.pending]: (state, action) => {
            notification("info", { message: "Working on It!" });
        },
        [getAllTestimonial.fulfilled]: (state, { payload }) => {
            state.list = payload;
            notification("success", { message: "Fetched Successfully." });
        },
        [getAllTestimonial.rejected]: (state, action) => {
            notification("error", { message: "Error Fetching Data!" });
        },
        [getOneTestimonial.pending]: (state, action) => {
            notification("info", { message: "Working on It!" });
        },
        [getOneTestimonial.fulfilled]: (state, action) => {
            notification("success", { message: "Fetched Successfully." });
        },
        [getOneTestimonial.rejected]: (state, action) => {
            notification("error", { message: "Error Fetching Data!" });
        },
        [changeIsPublishStatus.pending]: (state, action) => {
            // notification("info", { message: "Working on it!." });
        },
        [changeIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Testimonial doesn't exist or server error! " });
        },
        [putTestimonial.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putTestimonial.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putTestimonial.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Testimonial doesn't exist or validation error! " });
        },
        [searchTestimonial.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchTestimonial.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
    }
})
export default testimonialSlice;