import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";
export const getAllBanner = createAsyncThunk(
    "banner/getAllBanner",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/banner");
            return response.data.banner;
        }
        catch (err) {
            return rejectWithValue("Failed to get all advertisement!");
        }
    })

export const searchBanner = createAsyncThunk(
    "banner/searchBanner",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/banner/search/:${params.searchKey}`);
            return (await response).data.banner;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)

export const postBanner = createAsyncThunk(
    "banner/postBanner",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post("/admin/banner", params.formData);
            params.resetForm();
            params.navigate();
            return await response.data.banner;
        } catch (err) {
            return rejectWithValue("Failed to add banner!");
        }
    }
)
export const putBanner = createAsyncThunk(
    "banner/putBanner",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.put("/admin/banner", params.formData);
            const payLoad = response.data.banner;
            params.navigate();
            return response.data.banner;
        } catch (err) {
            return rejectWithValue("Update failed!");
        }
    }
)

export const changeIsPublishStatus = createAsyncThunk(
    "banner/changeIsPublishStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/banner/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.banner;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const getOneBanner = createAsyncThunk(
    "banner/getOneBanner",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/banner/get/${params.bannerId}`)
            params.fillForm(response.data.banner);
            return response.data.banner;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const deleteBanner = createAsyncThunk(
    "banner/deleteBanner",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/banner/delete/${params._id}`);
            return (await response).data.banner;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

const bannerSlice = createSlice({
    name: "banner",
    initialState: {
        list: [],
    },
    extraReducers: {
        [getAllBanner.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [getAllBanner.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch banners!" })
        },
        [getAllBanner.pending]: (state) => {
            notification("info", { message: "Working on it!" });
        },
        [postBanner.fulfilled]: (state, action) => {
            state.list.push(action.payload)
            notification("success", { message: "Success.", description: "Banner saved successfully." });
        },
        [postBanner.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [postBanner.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putBanner.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putBanner.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Banner doesn't exist or validation error! " });
        },
        [changeIsPublishStatus.pending]: (state, action) => {
            // notification("info", { message: "Working on it!." });
        },
        [changeIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Banner doesn't exist or server error! " });
        },
        [deleteBanner.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteBanner.fulfilled]: (state, action) => {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deleteBanner.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Banner doesn't exist or server error! " });
        },
        [searchBanner.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchBanner.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
        [getOneBanner.pending]: (state) => {
            notification("info", { message: "Fetching banner info!" });
        },
        [getOneBanner.fulfilled]: (state) => {
            notification("success", { message: "Successfully fetched." });
        },
        [getOneBanner.rejected]: (state) => {
            notification("error", { message: "Server error!" });
        }
    }
})
export default bannerSlice;
