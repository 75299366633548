import moment from "moment";
import momentTimezone from "moment-timezone";
import { Row, Col, Select, Divider } from "antd";
const { Option, OptGroup } = Select;


const TimeZonePicker = (props) => {
    const renderOptions = () => {
        const timezones = moment.tz.names();
        let mappedValues = {};
        let regions = [];

        timezones?.map(timezone => {
            const splitTimezone = timezone.split("/");
            const region = splitTimezone[0];
            if (!mappedValues[region]) {
                mappedValues[region] = [];
                regions.push(region);
            }
            mappedValues[region].push(timezone);
        });
        return regions.map(region => {
            const options = mappedValues[region].map(timezone => {
                return <Option key={timezone}>{timezone}</Option>;
            });
            return (
                <OptGroup
                    key={region}
                    title={<div style={{ fontSize: 30 }}>{region}</div>}
                >
                    {options}
                </OptGroup>
            );
        });
    }
    return (
        <div {...props}>
            <Col span={6} className="row-title">
                Timezone List:
            </Col>
            <Col span={18}>
                <Select
                    style={{ width: 350 }}
                    defaultValue={momentTimezone.tz.guess()}
                    onChange={value => props.onChange({
                        target: { value }
                    })}
                    value={props.value}
                >
                    {renderOptions()}
                </Select>
            </Col>
            <h5 style={{ color: "black" }}>{props.info && props.info} </h5>
        </div >
    )
}
export default TimeZonePicker