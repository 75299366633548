import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";
import { cmsServiceActions } from "./index"
import parseFormData from "../utils/parseFormData"


export const getAllService = createAsyncThunk(
    "service/getAllService",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/service");
            return response.data.service;
        }
        catch (err) {
            return rejectWithValue("Failed to get all service!");
        }
    })

export const searchService = createAsyncThunk(
    "service/searchService",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/service/search/:${params.searchKey}`);
            return (await response).data.service;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)

export const postService = createAsyncThunk(
    "service/postService",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post("/admin/service", params.formData);
            params.resetForm();
            return await response.data.service;
        } catch (err) {
            return rejectWithValue("Failed to add service!");
        }
    }
)
export const putService = createAsyncThunk(
    "service/putService",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put("/admin/service", params.formData);
            const formData = parseFormData(params.formData);
            dispatch(cmsServiceActions.updateService(formData));
            return response.data.service;
        } catch (err) {
            return rejectWithValue("Update failed!");
        }
    }
)

export const changeIsPublishStatus = createAsyncThunk(
    "service/changeIsPublishStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/service/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.service;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const getOneService = createAsyncThunk(
    "service/getOneService",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/service/get/${params.serviceId}`)
            params.fillForm(response.data.service);
            return response.data.service;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const deleteService = createAsyncThunk(
    "service/deleteService",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.delete(`/admin/service/${params._id}`);
            return (await response).data.service;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const postServiceCategory = createAsyncThunk(
    "service/postServiceCategory",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post(`/admin/service/category`);
            return response.data.category;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

const serviceSlice = createSlice({
    name: "service",
    initialState: {
        list: [],
        editItem: null,
    },
    reducers: {
        editItem(state, action) {
            state.editItem = action.payload;
        },
        initializeEditItem(state, action) {
            state.editItem = null;
        },
        updateService(state, action) {
            const objectIndex = state.list.findIndex(item => item._id === action.payload._id);
            objectIndex < 0 ? (() => { })() : (() => {
                state.list[objectIndex].serviceName = action.payload.serviceName;
                state.list[objectIndex].isPublish = action.payload.isPublish;
                state.list[objectIndex].description = action.payload.description;
            })()
        },
    },
    extraReducers: {
        [getAllService.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [getAllService.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch services!" })
        },
        [getAllService.pending]: (state) => {
            notification("info", { message: "Working on it!" });
        },
        [postService.fulfilled]: (state, action) => {
            state.list.push(action.payload)
            notification("success", { message: "Success.", description: "Service saved successfully." });
        },
        [postService.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [putService.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putService.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putService.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Service doesn't exist or validation error! " });
        },
        [changeIsPublishStatus.pending]: (state, action) => {
            // notification("info", { message: "Working on it!." });
        },
        [changeIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Service doesn't exist or server error! " });
        },
        [deleteService.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteService.fulfilled]: (state, action) => {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deleteService.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Service doesn't exist or server error! " });
        },
        [searchService.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchService.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
        [getOneService.pending]: (state) => {
            notification("info", { message: "Fetching service info!" });
        },
        [getOneService.fulfilled]: (state) => {
            notification("success", { message: "Successfully fetched." });
        },
        [getOneService.rejected]: (state) => {
            notification("error", { message: "Server error!" });
        },
        [postServiceCategory.pending]: (state) => {
            notification("info", { message: "Fetching service info!" });
        },
        [postServiceCategory.fulfilled]: (state) => {
            notification("success", { message: "Successfully fetched." });
        },
        [postServiceCategory.rejected]: (state) => {
            notification("error", { message: "Server error!" });
        }
    }
})
export default serviceSlice;