import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";
import { cmsTeamActions } from "./index";
export const postTeam = createAsyncThunk(
    "team/postTeam",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post("/admin/team", params.formData);
            dispatch(cmsTeamActions.addOneTeamToList(response.data.team))
            params.resetForm();
            params.navigate();
            return response.data.team;
        }
        catch (err) {
            return rejectWithValue("Failed to post team!")
        }
    }
)
export const getAllTeam = createAsyncThunk(
    "team/getAllTeam",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.get("/admin/team");
            return response.data.team;
        }
        catch (err) {
            return rejectWithValue("Falied to load team.")
        }
    }
)
export const getOneTeam = createAsyncThunk(
    "team/getOneTeam",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/team/get/${params._id}`);
            params.fillForm(response.data.team);
            return response.data.team;
        }
        catch (err) {
            return rejectWithValue("Failed To Fetch Team Info");
        }
    }
)

export const deleteTeam = createAsyncThunk(
    "team/deleteTeam",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.get(`/admin/team/delete/${params._id}`);
            dispatch(cmsTeamActions.deleteTeam(response.data.team));
            return response.data.team;
        }
        catch (err) {
            return rejectWithValue("Failed to delete team!");
        }
    }
)

export const changeIsPublishStatus = createAsyncThunk(
    "team/changeIsPublishStatus",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.get(`/admin/team/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.team;
        }
        catch (err) {
            return rejectWithValue("Failed to change status");
        }
    }
)

export const putTeam = createAsyncThunk(
    "team/putTeam",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put("/admin/team", params.formData);
            params.navigate();
            return response.data.team;
        }
        catch (err) {
            return rejectWithValue("Failed to update data!");
        }
    }
)

export const searchTeam = createAsyncThunk(
    "team/searchTeam",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/team/search/:${params.searchKey}`);
            return response.data.team;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)

const teamSlice = createSlice({
    name: "team",
    initialState: {
        list: [],
    },
    reducers: {
        deleteTeam(state, action) {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
        },
        addToTeamList(state, action) {
            state.list = action.payload;
        },
        addOneTeamToList(state, action) {
            state.list = [action.payload, ...state.list];
        },
    },
    extraReducers: {
        [postTeam.pending]: (state, action) => {
            notification("info", { message: "Working on It!" });
        },
        [postTeam.fulfilled]: (state, action) => {
            notification("success", { message: "Saved Successfully." });
        },
        [postTeam.rejected]: (state, action) => {
            notification("error", { message: "Error Saving Data!" });
        },
        [getOneTeam.pending]: (state, action) => {
            notification("info", { message: "Working on It!" });
        },
        [getOneTeam.fulfilled]: (state, action) => {
            notification("success", { message: "Fetched Successfully." });
        },
        [getOneTeam.rejected]: (state, action) => {
            notification("error", { message: "Error Fetching Data!" });
        },
        [getAllTeam.pending]: (state, action) => {
            notification("info", { message: "Working on It!" });
        },
        [getAllTeam.fulfilled]: (state, { payload }) => {
            state.list = payload;
            notification("success", { message: "Fetched Successfully." });
        },
        [getAllTeam.rejected]: (state, action) => {
            notification("error", { message: "Error Fetching Data!" });
        },
        [changeIsPublishStatus.pending]: (state, action) => {
            // notification("info", { message: "Working on it!." });
        },
        [changeIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Team doesn't exist or server error! " });
        },
        [putTeam.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putTeam.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putTeam.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Team doesn't exist or validation error! " });
        },
        [deleteTeam.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteTeam.fulfilled]: (state) => {
            notification("success", { message: "Success.", description: "Successfully deleted." });
        },
        [deleteTeam.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Team doesn't exist or validation error! " });
        },
        [searchTeam.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchTeam.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
    }
})

export default teamSlice;