import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";

export const getAllContact = createAsyncThunk(
    "/admin/contact/getAllContact",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/contact");
            return response.data.contact;
        }
        catch (err) {
            return rejectWithValue("Failed to get all contact!");
        }
    })

export const searchContact = createAsyncThunk(
    "contact/searchContact",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/contact/search/:${params.searchKey}`);
            return response.data.contact;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)


export const deleteContact = createAsyncThunk(
    "delete/deleteContact",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/contact/delete/${params._id}`);
            return response.data.contact;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)
const contactSlice = createSlice({
    name: "contact",
    initialState: {
        list: [],
    },
    extraReducers: {
        [getAllContact.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [getAllContact.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch contact!" })
        },
        [deleteContact.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteContact.fulfilled]: (state, action) => {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deleteContact.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Conatct doesn't exist or server error! " });
        },
        [searchContact.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchContact.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
    }
})
export default contactSlice;
