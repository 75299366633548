import firstLetterCaps from '../../../utils/firstLetterCaps';
import { Image, Avatar } from 'antd';
import React, { useState } from 'react';
import MediaSelect from "../../mediaSelectPortal";
import { useSelector } from "react-redux";
import classes from "./elements.module.css"
import UploadButton from '../../buttons/UploadButton';
import PreviewCard from '../../PreviewCard';

const MediaSelectElement = (props) => {
    const selectedMedia = useSelector(state => state.cmsMediaSelect.list)
    const [mediaSelectIsVisible, setMediaSelectIsVisible] = useState(false);
    const { className, ...params } = props;
    return (
        <div className={className}>
            <label>{params.label ? firstLetterCaps(params.label) : ""} {params.required && "*"}</label>
            <div className={classes["media-select-button-container"]}>
                <div className={classes["top"]}>
                    <UploadButton
                        onClick={() => setMediaSelectIsVisible(true)}
                    >
                        Select Media
                    </UploadButton>
                    <div className={classes["media-count"]}>{selectedMedia.length > 0 ?
                        firstLetterCaps(`${selectedMedia.length} items selected.`) :
                        firstLetterCaps("No media selected")}
                    </div>
                </div>
                <div >
                    {selectedMedia.length > 0 && <section
                        className={classes["preview"]}>
                        {selectedMedia.map((item, index) =>
                            <PreviewCard item={item} key={index} />
                        )}
                    </section>
                    }
                </div>
            </div>

            <MediaSelect
                isVisible={mediaSelectIsVisible}
                onCancel={() => {
                    setMediaSelectIsVisible(false);
                }}
                onConfirm={() => {
                    setMediaSelectIsVisible(false)
                }}
                multiple={props.multiple}
            />
            <h5 style={{ color: "black" }}>{params.info && params.info}</h5>
            <h5 style={{ color: "rgb(242, 20, 20)" }}>{props.error ? props.errorMessage : ""}</h5>
        </div >
    )
}
export default MediaSelectElement;