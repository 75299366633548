import React from 'react';
import { Input } from 'antd';
import firstLetterCaps from '../../../utils/firstLetterCaps';
import { AiFillPlusCircle } from "react-icons/ai"
import classes from "./elements.module.css"
import { useState } from 'react';
const DynamicTextField = (props) => {
    const { className, placeholder, value, ...params } = props;
    const { fieldCount, setFiledCount } = useState(() => props?.officeNumber?.length - 1)
    return (
        <>
            <div className={className} >
                <label>{props.label ? firstLetterCaps(props.label) : ""} {params.required && "*"}</label>
                <div className={classes.field}>
                    <Input
                        status={params.error && "error"}
                        placeholder={placeholder}
                        value={value[0]}
                        {...params}
                    />
                    {value.map((val, index) => {
                        <Input
                            key={index}
                            status={params.error && "error"}
                            placeholder={placeholder}
                            value={value[0]}
                            {...params}
                        />
                    })}
                    <div className={classes.plusButton}>
                        <AiFillPlusCircle size={30} />
                    </div>
                </div>
                <h5 style={{ color: "black" }}>{params.info && params.info}</h5>
                <h5 style={{ color: "rgb(242, 20, 20)" }}>{params.error && params.errormessage}</h5>
            </div>
        </>
    )
}
export default DynamicTextField