import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";
export const getAllSubscriber = createAsyncThunk(
    "subscriber/getAllSubscriber",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/subscriber");
            return response.data.subscriber;
        }
        catch (err) {
            return rejectWithValue("Failed to get all subscriber!");
        }
    })

export const searchSubscriber = createAsyncThunk(
    "subscriber/searchSubscriber",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/subscriber/search/:${params.searchKey}`);
            return response.data.subscriber;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)

export const deleteSubscriber = createAsyncThunk(
    "delete/deleteSubscriber",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/subscriber/delete/${params._id}`);
            return response.data.subscriber;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)
const subscriberSlice = createSlice({
    name: "subscriber",
    initialState: {
        list: [],
    },
    extraReducers: {
        [getAllSubscriber.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [getAllSubscriber.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch subscriber!" })
        },
        [deleteSubscriber.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteSubscriber.fulfilled]: (state, action) => {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deleteSubscriber.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Subscriber doesn't exist or server error! " });
        },
        [searchSubscriber.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchSubscriber.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
    }
})
export default subscriberSlice;
