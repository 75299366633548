import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";

export const getAllProject = createAsyncThunk(
    "project/getAllProject",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/project");
            return response.data.project;
        }
        catch (err) {
            return rejectWithValue("Failed to get all project!");
        }
    })

export const searchProject = createAsyncThunk(
    "project/searchProject",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/project/search/:${params.searchKey}`);
            return (await response).data.project;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)

export const postProject = createAsyncThunk(
    "project/postProject",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post("/admin/project", params.formData);
            params.resetForm();
            params.navigate();
            return await response.data.project;
        } catch (err) {
            return rejectWithValue("Failed to add project!");
        }
    }
)
export const putProject = createAsyncThunk(
    "project/putProject",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.put("/admin/project", params.formData);
            const payLoad = response.data.project;
            params.navigate();
            return response.data.project;
        } catch (err) {
            return rejectWithValue("Update failed!");
        }
    }
)

export const changeIsPublishStatus = createAsyncThunk(
    "project/changeIsPublishStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/project/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.project;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)
export const changeIsFeaturedStatus = createAsyncThunk(
    "project/changeIsFeaturedStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/project/changeIsFeatured/${params._id}`);
            params.enableSwitch();
            return response.data.project;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)
export const getOneProject = createAsyncThunk(
    "project/getOneProject",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/project/get/${params.projectId}`)
            params.fillForm(response.data.project);
            return response.data.project;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const deleteProject = createAsyncThunk(
    "project/deleteProject",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/project/delete/${params._id}`);
            return (await response).data.project;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

const projectSlice = createSlice({
    name: "project",
    initialState: {
        list: [],
    },
    extraReducers: {
        [getAllProject.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [getAllProject.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch projects!" })
        },
        [getAllProject.pending]: (state) => {
            notification("info", { message: "Working on it!" });
        },
        [postProject.fulfilled]: (state, action) => {
            state.list.push(action.payload)
            notification("success", { message: "Success.", description: "Project saved successfully." });
        },
        [postProject.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [putProject.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putProject.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putProject.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Project doesn't exist or validation error! " });
        },
        [changeIsPublishStatus.pending]: (state, action) => {
            // notification("info", { message: "Working on it!." });
        },
        [changeIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Project doesn't exist or server error! " });
        },
        [changeIsFeaturedStatus.pending]: (state, action) => {
            // notification("info", { message: "Working on it!." });
        },
        [changeIsFeaturedStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeIsFeaturedStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Project doesn't exist or server error! " });
        },
        [deleteProject.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteProject.fulfilled]: (state, action) => {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deleteProject.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Project doesn't exist or server error! " });
        },
        [searchProject.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchProject.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
        [getOneProject.pending]: (state) => {
            notification("info", { message: "Fetching project info!" });
        },
        [getOneProject.fulfilled]: (state) => {
            notification("success", { message: "Successfully fetched." });
        },
        [getOneProject.rejected]: (state) => {
            notification("error", { message: "Server error!" });
        }
    }
})
export default projectSlice;