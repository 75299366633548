import classes from "./Buttons.module.css";
import { AiOutlinePlusSquare } from "react-icons/ai";
const UploadButton = (props) => {
    return (
        <button type="button" className={classes["upload-button"]} {...props}>
            <div className={classes["text"]}>{props.children}</div>
            <AiOutlinePlusSquare size={30} color="#b4b4b4" />
        </button>
    )
}
export default UploadButton;