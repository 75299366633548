import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";

export const getAllBlog = createAsyncThunk(
    "/admin/blog/getAllBlog",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get("/admin/blog");
            return response.data.blog;
        }
        catch (err) {
            return rejectWithValue("Failed to get all blog!");
        }
    })

export const searchBlog = createAsyncThunk(
    "/admin/blog/searchBlog",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/blog/search/:${params.searchKey}`);
            return (await response).data.blog;
        } catch (err) {
            return rejectWithValue("Not items found!");
        }
    }
)

export const postBlog = createAsyncThunk(
    "/admin/blog/postBlog",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post("/admin/blog", params.formData);
            params.resetForm();
            params.navigate();
            return await response.data.blog;
        } catch (err) {
            return rejectWithValue("Failed to add blog!");
        }
    }
)
export const putBlog = createAsyncThunk(
    "/admin/blog/putBlog",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.put("/admin/blog", params.formData);
            const payLoad = response.data.blog;
            params.navigate();
            return (await response).data.blog;
        } catch (err) {
            return rejectWithValue("Update failed!");
        }
    }
)

export const changeIsPublishStatus = createAsyncThunk(
    "/admin/blog/changeIsPublishStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/blog/changeIsPublish/${params._id}`);
            params.enableSwitch();
            return response.data.advertisement;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const changeIsFeaturedStatus = createAsyncThunk(
    "/admin/blog/changeIsFeaturedStatus",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/blog/changeIsFeatured/${params._id}`);
            params.enableSwitch();
            return response.data.advertisement;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const getOneBlog = createAsyncThunk(
    "/admin/blog/getOneBlog",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/blog/get/${params.blogId}`)
            params.fillForm(response.data.blog);
            return response.data.blog;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

export const deleteBlog = createAsyncThunk(
    "/admin/blog/deleteBlog",
    async (params, { rejectWithValue }) => {
        try {
            const response = axios.get(`/admin/blog/delete/${params._id}`);
            return (await response).data.blog;
        } catch (err) {
            return rejectWithValue("Failed!");
        }
    }
)

const blogSlice = createSlice({
    name: "blog",
    initialState: {
        list: [],
    },
    extraReducers: {
        [getAllBlog.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [getAllBlog.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Cannot fetch blogs!" })
        },
        [postBlog.pending]: (state) => {
            notification("info", { message: "Working on it!" });
        },
        [postBlog.fulfilled]: (state, action) => {
            state.list.push(action.payload)
            notification("success", { message: "Success.", description: "Blog saved successfully." });
        },
        [postBlog.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [putBlog.pending]: (state) => {
            notification("info", { message: "Working on it!." });
        },
        [putBlog.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [putBlog.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Blog doesn't exist or validation error! " });
        },
        [changeIsPublishStatus.pending]: (state, action) => {
            // notification("info", { message: "Working on it!." });
        },
        [changeIsPublishStatus.fulfilled]: (state, action) => {
            notification("success", { message: "Success.", description: "Status changed successfully." });
        },
        [changeIsPublishStatus.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Blog doesn't exist or server error! " });
        },
        [deleteBlog.pending]: (state, action) => {
            notification("info", { message: "Working on it!." });
        },
        [deleteBlog.fulfilled]: (state, action) => {
            !state.list.length ? (() => { })() : (() => {
                const objectIndex = current(state).list.findIndex(item => item._id === action.payload._id);
                objectIndex < 0 ? (() => { })() : state.list.splice(objectIndex, 1);
            })();
            notification("success", { message: "Success.", description: "Deleted successfully." });
        },
        [deleteBlog.rejected]: (state, action) => {
            notification("error", { message: "Error!", description: "Blog doesn't exist or server error! " });
        },
        [searchBlog.fulfilled]: (state, { payload }) => {
            state.list = payload;
        },
        [searchBlog.rejected]: (state, { payload }) => {
            notification("error", { message: "Error!", description: "No match Found!" });
        },
        [getOneBlog.pending]: (state) => {
            notification("info", { message: "Fetching blog info!" });
        },
        [getOneBlog.fulfilled]: (state) => {
            notification("success", { message: "Successfully fetched." });
        },
        [getOneBlog.rejected]: (state) => {
            notification("error", { message: "Server error!" });
        }
    }
})
export default blogSlice;