import React, { Suspense } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import CmsPanel from "../pages/cms/CmsPanel";
import { useSelector } from "react-redux";
const Login = React.lazy(() => import("../pages/cms/login/Login"));
const PageNotFound = React.lazy(() => import("../pages/404"));

const SiteRouter = () => {
  const user = useSelector((state) => state.cmsAuth.user);
  const location = useLocation();
  return (
    <Suspense fallback={<h3>Loading...</h3>}>
      <Routes location={location} key={location.pathname}>
        <Route
          exact
          path="/login"
          element={!user ? <Login /> : <Navigate to={"/"} />}
        ></Route>
        <Route path="/*" element={<CmsPanel />} />
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </Suspense>
  );
};
export default SiteRouter;
